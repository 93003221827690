import React from 'react';

import { useRouter } from 'next/router';
import { DrawerOptions, DrawerAuthOption } from 'constants/index';

import { useSelector } from 'store';
import { useDispatch } from 'react-redux';

import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import { PostLogoutAPI } from 'api/accounts';
import { AxiosResponse } from 'axios';
import { accountActions } from 'store/accounts';
import { membershipActions } from 'store/membership';
import { DeleteFCMToken } from 'api/notifications';
import { makeAuthorizationConfig } from 'utils';

// Drawer 추가...
interface IProps {
  closeDrawer: () => void;
}

const HeaderLinks: React.FC<IProps> = ({ closeDrawer }: IProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const path = router.pathname;
  const isLogin = useSelector(state => state.accounts.isLogin);
  const [cookies] = useCookies();

  const clickLogout = async () => {
    const config = makeAuthorizationConfig(cookies.access_token);

    Cookies.remove('access_token', { path: '/' });
    Cookies.remove('refresh_token', { path: '/' });

    const fcmToken = localStorage.getItem('fcmToken');

    if (fcmToken) {
      await DeleteFCMToken(fcmToken, config);
    }

    const response: AxiosResponse = await PostLogoutAPI();

    if (!response || response.status >= 400) {
      alert('로그아웃에 실패했습니다.');
      window.location.href = '/';
      return;
    }

    dispatch(accountActions.setLogout());
    dispatch(membershipActions.setLogoutMember());

    if (router.pathname === '/') {
      window.location.reload();
    } else {
      router.push('/');
    }
  };

  const clickRoute = href => {
    router.push(href);
  };

  return (
    <div className="menu-w selected">
      <div className="inner">
        <ul className="menu">
          {isLogin
            ? DrawerAuthOption.map((option, index) => {
                if (
                  option.href === path ||
                  option.children?.some(child => child === path)
                ) {
                  return (
                    <li className="selected" key={index}>
                      <a
                        onClick={() => clickRoute(option.href)}
                        style={{ cursor: 'pointer' }}
                      >
                        {option.name}
                      </a>
                    </li>
                  );
                }
                return (
                  <li key={index}>
                    <a
                      onClick={() => clickRoute(option.href)}
                      style={{ cursor: 'pointer' }}
                    >
                      {option.name}
                    </a>
                  </li>
                );
              })
            : DrawerOptions.map((option, index) => {
                if (
                  option.href === path ||
                  option.children?.some(child => child === path)
                ) {
                  return (
                    <li className="selected" key={index}>
                      <a
                        onClick={() => clickRoute(option.href)}
                        style={{ cursor: 'pointer' }}
                      >
                        {option.name}
                      </a>
                    </li>
                  );
                }
                return (
                  <li key={index}>
                    <a
                      onClick={() => clickRoute(option.href)}
                      style={{ cursor: 'pointer' }}
                    >
                      {option.name}
                    </a>
                  </li>
                );
              })}
          {isLogin ? (
            <li onClick={clickLogout} style={{ cursor: 'pointer' }}>
              <a>로그아웃</a>
            </li>
          ) : null}
        </ul>
        <button type="button" className="btn-close" onClick={closeDrawer}>
          <span>메뉴 닫기</span>
        </button>
      </div>
    </div>
  );
};

export default HeaderLinks;
